import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import z from "zod";
import mainStore from "../store/MainStore";

function GooglePayButton({
  store = {},
  entrypoint,
  callBackFunction,
  callBackFunctionError,
  beforeClick,
}) {
  useEffect(() => {
    if (!entrypoint || !store) return;
    const googlePayInstance = new GooglePay({
      entryPoint: entrypoint,
      supportedNetworks: store.allowedCards,
      dbaName: store.paypointDbaName,
      appearance: store.paymentPage?.paymentMethods?.settings?.googlePay || {},
      onSuccess: (data) => callBackFunction(data, "googlepay"),
      onError: (error) => callBackFunctionError(error),
      beforeClick: beforeClick,
    });
    googlePayInstance.loadScript().then((res) => {
      if (res.ok) {
        googlePayInstance.createButton({
          fee: mainStore.totalAmount.fee,
          amount: mainStore.totalAmount.netAmount,
          currency: "USD",
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entrypoint,
    store.paymentPage?.paymentMethods?.settings?.googlePay,
    mainStore.totalAmount
  ]);

  return (
    <>
      <div style={{ height: "54px" }} id="google-pay-button" />
      <Helmet>
        <style type="text/css">{`
            #google-pay-button iframe {
              padding: 5px;
            }
        `}</style>
      </Helmet>
    </>
  );
}

export default inject("store")(observer(GooglePayButton));

class GooglePay {
  static SUPPORTED_BUTTON_TYPES = [
    "book",
    "buy",
    "checkout",
    "donate",
    "order",
    "pay",
    "plain",
    "long",
    "short",
  ];
  constructor({
    supportedNetworks,
    entryPoint,
    dbaName,
    appearance,
    onSuccess = () => {},
    onError = () => {},
    beforeClick = new Promise((resolve) => resolve()),
  }) {
    this.urlApi = process.env.REACT_APP_URL_API;
    this.entryPoint = entryPoint;
    this.supportedNetworks = supportedNetworks || [];
    this.dbaName = dbaName || "";
    this.appearance = {
      buttonStyle: appearance.buttonStyle || "black",
      buttonType: GooglePay.SUPPORTED_BUTTON_TYPES.includes(
        appearance.buttonType
      )
        ? appearance.buttonType
        : "pay",
      language: appearance.language || "en",
    };
    this.baseRequest = { apiVersion: 2, apiVersionMinor: 0 };
    this.domain = window.location.origin;
    this.initPaymentMethods();
    this.onSuccess = onSuccess;
    this.onError = onError;
    if(beforeClick){
      this.beforeClick = beforeClick
    }
  }

  initPaymentMethods() {
    const tokenizationSpecification = {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "payabli",
        gatewayMerchantId: this.entryPoint,
      },
    };

    this.baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: this.supportedNetworks.map((v) => v.toUpperCase()),
      },
    };

    this.cardPaymentMethod = {
      ...this.baseCardPaymentMethod,
      tokenizationSpecification,
    };

    this.isReadyToPayRequest = {
      ...this.baseRequest,
      allowedPaymentMethods: [this.baseCardPaymentMethod],
    };
  }

  encodeBase64(str) {
    return btoa(String.fromCharCode(...new TextEncoder().encode(str)));
  }

  async onPaymentAuthorized(paymentData, { fee, amount, customerData }) {
    try {
      const totalAmount = Number(amount) + Number(fee);
      const walletToken = this.encodeBase64(JSON.stringify(paymentData));
      const invoiceData = mainStore.getInvoiceData();
      const payload = {
        entryPoint: this.entryPoint,
        paymentMethod: {
          method: "wallet",
          walletType: "google_pay",
          walletToken,
        },
        paymentDetails: {
          totalAmount: Number(totalAmount).toFixed(2),
          serviceFee: Number(fee).toFixed(2),
        },
        source: "payment-link",
        customerData,
        ...(invoiceData && {
          invoiceData,
        }),
      };

      const response = await fetch(
        `${this.urlApi}MoneyIn/getpaid?async=false`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            requestToken: mainStore.getPageToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Payment authorization failed");
      }

      const data = await response.json();

      if (data.pageIdentifier) {
        mainStore.setIdentifier(data.pageIdentifier);
      }

      this.onSuccess(data);

      return { transactionState: "SUCCESS" };
    } catch (error) {
      return {
        transactionState: "ERROR",
        error: {
          reason: "PAYMENT_DATA_INVALID",
          message: error.message,
          intent: "PAYMENT_AUTHORIZATION",
        },
      };
    }
  }

  async googlePayInit(args) {
    try {
      if (!window.google || !window.google.payments) {
        throw new Error("Google Pay SDK not loaded");
      }

      const validation = z
        .object({
          fee: z.number().nonnegative(),
          amount: z.number().nonnegative(),
        })
        .safeParse(args);
      if (!validation.success) {
        throw new Error(validation.error.message);
      }

      const { fee, amount } = validation.data;

      const environment = process.env.REACT_APP_URL_API !== 'https://api.payabli.com/api/' ? 'TEST' : 'PRODUCTION';
      // eslint-disable-next-line no-undef
      const paymentsClient = new google.payments.api.PaymentsClient({
        environment,
        paymentDataCallbacks: {
          onPaymentAuthorized: (paymentData) =>
            this.onPaymentAuthorized(paymentData, {
              fee,
              amount,
              customerData: mainStore.getRequiredPayorFieldsToPay(),
            }),
        },
      });

      this.paymentsClient = paymentsClient;

      if (!this.isReadyToPayRequest) {
        throw new Error("isReadyToPayRequest is missing");
      }

      const response = await paymentsClient.isReadyToPay(
        this.isReadyToPayRequest
      );
      if (!response.result) {
        throw new Error("Google Pay is not available for this user");
      }

      return { ok: true };
    } catch (error) {
      console.error("Google Pay initialization failed:", error);
      throw error;
    }
  }

  createButton(args) {
    const validation = buttonValidator.safeParse(args);
    if (!validation.success) {
      throw new Error(validation.error.message);
    }

    const { fee, amount, currency } = validation.data;
    const paymentDataRequest = this.getPaymentDataRequest({
      fee,
      amount,
      currency,
      entryPoint: this.entryPoint,
      dbaName: this.dbaName,
    });

    const button = this.paymentsClient.createButton({
      onClick: async () => {
        try {
            await this?.beforeClick?.();
          await this.paymentsClient.loadPaymentData(paymentDataRequest);
        } catch (error) {
          console.error("Google Pay Error:", error);
        }
      },
      allowedPaymentMethods: [],
      buttonLocale: this.appearance?.language || "en",
      buttonColor: this.appearance?.buttonStyle || "black",
      buttonType: this.appearance?.buttonType || "pay",
      buttonSizeMode: "fill",
    });

    const buttonContainer = document.getElementById("google-pay-button");
    if (!buttonContainer) {
      throw new Error("Google Pay button container not found");
    }
    const googlePayMounted = buttonContainer.querySelector(
      ".gpay-card-info-container-fill"
    );
    if (googlePayMounted) {
      googlePayMounted.remove();
    }
    buttonContainer.appendChild(button);
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById("google-pay-script");

      if (existingScript) {
        if (window.google && window.google.payments) {
          console.info("Google Pay SDK already loaded. Initializing...");
          return this.googlePayInit({
            fee: mainStore.totalAmount.fee,
            amount: mainStore.totalAmount.netAmount,
          })
            .then(() => resolve({ ok: true }))
            .catch((error) => {
              console.error("Google Pay initialization failed:", error);
              reject(error);
            });
        } else {
          console.warn(
            "Google Pay script found but SDK not ready. Retrying..."
          );
          return setTimeout(
            () => this.loadScript().then(resolve).catch(reject),
            500
          );
        }
      }

      const script = document.createElement("script");
      script.id = "google-pay-script";
      script.src = "https://pay.google.com/gp/p/js/pay.js";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (!window.google || !window.google.payments) {
          console.error("Google Pay SDK failed to initialize properly");
          return reject(
            new Error("Google Pay SDK not available after script load")
          );
        }

        console.info("Google Pay SDK loaded successfully. Initializing...");
        this.googlePayInit({
          fee: mainStore.totalAmount.fee,
          amount: mainStore.totalAmount.totalAmount,
        })
          .then(resolve)
          .catch((error) => {
            console.error("Google Pay initialization failed:", error);
            reject(error);
          });
      };

      script.onerror = (error) => {
        console.error("Failed to load Google Pay script:", error);
        reject(error);
      };
    });
  }

  getPaymentDataRequest({ fee, amount, currency, dbaName }) {
    return {
      ...this.baseRequest,
      allowedPaymentMethods: [this.cardPaymentMethod],
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPrice: (Number(amount) + Number(fee)).toFixed(2),
        currencyCode: currency,
        countryCode: "US",
      },
      merchantInfo: {
        merchantName: dbaName,
        merchantId: this.entryPoint,
        merchantOrigin: this.domain,
      },
      callbackIntents: ["PAYMENT_AUTHORIZATION"],
    };
  }
}

const buttonValidator = z.object({
  fee: z.number().nonnegative(),
  amount: z.number().nonnegative(),
  currency: z.string().default("USD"),
});
